import React from 'react'

const BlogData = ({data}) => {
    // console.log("bbbbbbbbb:::::::",data)
  return (
    <> 
        <div className="item my-2">
            <div className="card">
                <img draggable="false" src={`/Assets/${data.Image}`} className="card-img-top p-3" alt="blog-img" />
                <div className="card-body">
                    <h5 className="card-title fw-bold">{data.Description}</h5>
                </div>
            </div>
        </div>
    </>
  )
}

export default BlogData