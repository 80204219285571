import React from 'react'

const HiringRequirement = ({data,dataindex}) => {
    
    // console.log("allhire",data)
    // console.log("allindexget::::::::::",dataindex)
    var passid = "";
    if(dataindex == 0){
        passid = "One"
    }else if(dataindex == 1){
        passid = "Two"
    }else if(dataindex == 2){
        passid = "Three"
    }else if(dataindex == 3){
        passid = "Four"
    }else if(dataindex == 4){
        passid = "Five"
    }else if(dataindex == 5){
        passid = "Six"
    }else if(dataindex == 6){
        passid = "Seven"
    }else if(dataindex == 7){
        passid = "Eight"
    }else{
        passid = "nine"
    }
//    var idcount=idcount+1;
  return (
    <>

                    <div className="accordion-item wow fadeInRight" data-wow-delay="0.5s">
                        <h2 className="accordion-header" id={`flush-heading${passid}`}>
                            <div className="accordion-button fw-Med cl-o collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target={`#flush-collapse${passid}`} aria-expanded="false"
                                aria-controls={`flush-collapse${passid}`}>
                                <p><b>{data.post_name}</b><span className="overBlack f-20">&nbsp;{`(Position : ${data.position})`}</span></p>
                            </div>
                        </h2>
                        <div id={`flush-collapse${passid}`} className="accordion-collapse collapse"
                            aria-labelledby={`flush-heading${passid}`} data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body content content_res">
                                <p><b>Job Position Available : </b>{data.position}</p>
                                <p><b>Experience : </b>{data.experience}</p>
                                <p><b>Qualification : </b>{data.qualification}</p>
                                <p><b>Salary : </b>{data.salary}</p>
                                <p><b>Location : </b>{data.location}</p>
                                <p><b>Skill Requirement : </b>{data.requried_skill}</p>
                                <p><b>Key skill : </b>{data.key_skill}</p>
                            </div>
                        </div>
                    </div>
    </>
  )
}

export default HiringRequirement