import React from 'react'

const TechnologyStack = () => {
  return (
    <>
    <h1 className="text-center fw-bolder mt-5 mb-3 f-title">
    <i className="fad fa-horizontal-rule"></i> Technology Stack <i className="fad fa-horizontal-rule"></i>
</h1>
<h4 className="text-center fw-bolder f-title mb-lg-5 mb-md-4 mb-3 ">These all are technology we use </h4>
<section className="m-100" id="techStack">
    <div className="tech-background">
        <div className="container">
            <h2 className="fw-bold f-title text-center mb-3">Web Development:</h2>
            <div className="row">
                <div className="col-lg-9 mx-auto">
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="html"> <i className="fab fa-html5"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">HTML 5</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="css"> <i className="fab fa-css3-alt"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">CSS 3</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="js"> <i className="fab fa-js-square"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Java Script</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="bt"> <i className="fab fa-bootstrap"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Bootstrap</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="php"><i className="fab fa-php"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">PHP</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="ang"><i className="fab fa-angular"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Angular js</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="react"><i className="fab fa-react"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">React js</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="vue"><i className="fab fa-vuejs"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Vue js</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="laravel"><i className="fab fa-laravel"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Laravel</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="node"><i className="fab fa-node-js"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Node Js</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <img draggable="false" src="/Assets/images/nextjs.png" alt="flutter" />
                            </div>
                            <h4 className="text-center fw-bold">Next Js</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <img draggable="false" src="/Assets/images/electronjs.png" alt="flutter" />
                            </div>
                            <h4 className="text-center fw-bold">Electron js</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="cod"><i className="fab fa-hotjar"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Codeigniter</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="python"><i className="fab fa-python"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Python</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h2 className="fw-bold f-title text-center mb-3 mt-3">App Development:</h2>
            <div className="row">
                <div className="col-lg-9 mx-auto">
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="java"><i className="fab fa-java"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Java</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="react"><i className="fab fa-react"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">React native</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="ios"><i className="fab fa-swift"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Swift(IOS)</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="native"><i className="fab fa-android"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Native android</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <img draggable="false" src="/Assets/images/flutter.png" alt="flutter" />
                            </div>
                            <h4 className="text-center fw-bold">Flutter</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <img draggable="false" src="/Assets/images/Kotlin_Icon.png" alt="flutter" height="46px" />
                            </div>
                            <h4 className="text-center fw-bold">Kotlin</h4>
                        </div>
                        <div className="technology">
                            <div className="tech-icon-bac">
                                <h1 className="unity"><i className="fab fa-unity"></i></h1>
                            </div>
                            <h4 className="text-center fw-bold">Unity 3D</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}

export default TechnologyStack