import React from 'react'

const Testimonial = () => {
  return (
    <>
<section>
    <div className="container">
        <h1 className="text-center fw-bolder mt-5 mb-3 f-title">
            <i className="fad fa-horizontal-rule"></i> Real Testimonials <i className="fad fa-horizontal-rule"></i>
        </h1>
        <h4 className="text-center fw-bolder f-title mb-lg-5 mb-md-4 mb-3">What Our Client Says About Us</h4>
        <div className="owl-carousel testimonial mt-4">
            <div className="item">
                <div className="testimonial-item">
                    <h1 className="quote"><i className="fas fa-quote-right"></i></h1>
                    <div className="d-flex align-items-center flex-wrap mb-3">
                            <div className="testimonial-img img-responsive me-3">
                                <img draggable="false" src="Assets/Testimonials/client.png" alt="testimonial-img"
                                    className="h-100 m-atuo" />
                            </div>
                            <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}

export default Testimonial