import React, { useEffect, useState } from 'react'
import {blog} from '../api'
import BlogData from './BlogData';
import OwlCarousel from 'react-owl-carousel'; 
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Blog = () => {
    const state= {
        responsive:{
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
    }
    const [allblog,setAllblog] = useState([]);
    useEffect(()=>{
        const blogcallfunction=async()=>{
            const {data} =await blog()
            setAllblog(data.BlogData)
        }
        blogcallfunction()
    },[])
  return (
    <>
    <section className="m-100" id="blog">
    <h1 className="text-center fw-bolder mt-5 mb-3 f-title">
        <i className="fad fa-horizontal-rule"></i> Our Blogs <i className="fad fa-horizontal-rule"></i>
    </h1>
    <h4 className="text-center fw-bolder f-title mb-lg-5 mb-md-4 mb-3 ">Our Latest Media</h4>
    <div className="container">
        <div className="blog">
        <OwlCarousel margin={15} loop={true} autoplay={true} dotsEach= {true} responsive={state.responsive}>
            {allblog.map((singledata, index) => {
             return <BlogData data={singledata} key={index}  setallblog={setAllblog}/>
            })}
        </OwlCarousel>
        </div>
    </div>
    </section>
    </>
  )
}
export default Blog