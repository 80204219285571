import React, { useEffect, useState } from 'react'
import {team} from '../api'
import TeamData from './TeamData';
import OwlCarousel from 'react-owl-carousel';   
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
const TeamMembers = () => {
    const state= {
        responsive:{
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
    }
    const [allteam,setAllTeam] = useState([]);
    useEffect(()=>{
        const callfunction=async()=>{
            const {data} = await team()
            setAllTeam(data.TeamData)
        }
        callfunction()
    },[])
  return (
    <>
<section className="m-100">
    <h1 className="text-center fw-bolder mt-5 mb-3 f-title">
        <i className="fad fa-horizontal-rule"></i> Our Team <i className="fad fa-horizontal-rule"></i>
    </h1>
    <h4 className="text-center fw-bolder f-title mb-lg-5 mb-md-4 mb-3">Our experience Team Members</h4>
    <div className="container">
        <div className="team">
            <OwlCarousel margin={15} loop={true} autoplay={true} dotsEach= {true} autoplayHoverPause={true} responsive={state.responsive}>
                {allteam.map((singledata, index) => {
                return <TeamData data={singledata} key={index}  setallteam={setAllTeam}/>
                })}
            </OwlCarousel>
        </div>
    </div>
</section>
    </>
  )
}
export default TeamMembers