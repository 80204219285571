import React, { useEffect, useState } from 'react'
import {gallery, blog,team,total,testimonial,galleryvideo,contactus} from '../api'
import About from '../components/About'
import Blog from '../components/Blog'
import Clients from '../components/Clients'
import Gallery from '../components/Gallery'
import Service from '../components/Service'
import Testimonial from '../components/Testimonial'
import Website from '../components/Website'
import TeamMembers from '../components/TeamMembers'
import TechnologyStack from '../components/TechnologyStack'
import Hiring from '../components/Hiring'
import NewsLetters from '../components/NewsLetters'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import MainBanner from '../components/MainBanner'
import $ from 'jquery';
window.$ = $;
const Home = () => {
    const [allblog,setAllblog] = useState([]);
    const [alltotal,setAlltotal] = useState([]);
    const [alltestimonial,setAlltestimonial] = useState([]);
//     const [allgalleryvideo,setAllgalleryvideo] = useState([]);
 
    useEffect(()=>{
        const blogcallfunction=async()=>{
            const {data} =await blog()
            setAllblog(data.BlogData)
        }
        blogcallfunction()
    },[])
   
  
    useEffect(()=>{
        const callfunction=async()=>{
            const {data} =await total()
            setAlltotal(data.TotalData)
        }
        callfunction()
    },[])
    useEffect(()=>{
        const callfunction=async()=>{
            const {data} =await testimonial()
            setAlltestimonial(data.TestimonialData)
        }
        callfunction()
    },[])
   
//     setTimeout(function(){
//       $('#myTable').DataTable({
//         destroy: false,
//         Data: business1
//       });
//   },1000);
  return (
    <>
{/* <!-- ================== About Us ================== --> */}
        <MainBanner />
        <About />
{/* <!-- ================== Service ==================--> */}
        <Service />
{/* <!-- =========================== Gallary============================= --> */}
        <Gallery />
{/* <!-- ===================== Numbers Of Clients And More ================= --> */}
{
                        alltotal.map((singletotal, index) => {
                            return <Clients data={singletotal} key={index}  setAlltotal={setAlltotal}/>
                        })
                    }
        {/* <Clients alltotal={alltotal}/> */}
{/* <!-- ================== Testimonial ================== --> */}
        <Testimonial/>
{/* <!-- ======================== WebSite ======================== --> */}
        <Website/>
{/* <!-- =================== Our Blog =================== --> */}
        <Blog/>
{/* <!-- =================== Team Members ============= --> */}
        <TeamMembers />
{/* <!-- ================= Technology stack ============= --> */}
        <TechnologyStack />
{/* <!-- ========================= Hiring ========================== --> */}
        <Hiring />
{/* <!-- ================== News Later ============== --> */}
        <NewsLetters />
{/* =================== Contact Us ====================*/}
        <ContactUs />

        <Footer />
    </>
  )
}

export default Home