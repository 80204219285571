import React from 'react'

const TeamData = ({data}) => {
  return (
    <>
      <div className="item team-members">
          <div className="team-item" style={{backgroundImage:`url(https://admin.doubleplusinfotech.com/public${data.Image})`}}>
              <div className="team-item-content d-flex flex-column h-100">
                  <h3 className="text-center fw-bold team-text mt-auto">{data.Name}</h3>
                  <h5 className="text-center fw-bold team-text mb-3">{data.Post}</h5>
                  <div className="team-social-icon d-flex justify-content-evenly">
                  </div>
              </div>
          </div>
      </div>
    </>
  )
}

export default TeamData