import axios from 'axios';
const api =axios.create({
    baseURL:"https://admin.doubleplusinfotech.com/api/",
    headers:{
        Accept:"application/json",
        'Content-type':"application/json"
    }
})

export const gallery= ()=>api.get('GetGalleryData')
export const blog= ()=>api.get('GetBlogData')
export const team= ()=>api.get('GetTeamData')
export const total= ()=>api.get('GetTotalData')
export const testimonial= ()=>api.get('GetTestimonialData')
export const galleryvideo= ()=>api.get('GetGalleryVideoData')
export const HiringRequirements= ()=>api.get('GetHiringRequirements')
export const contactus= (data)=>api.post('ContactUs',data)
export const newsletter= (data)=>api.post('NewsLetter',data)

export const hiring= (data)=>api.post('Hiring',data)