import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <>
    <section id="aboutus">
    <div className="container">
        <div className="row mt-4">
            <div className="col-lg-6 about-us">
                <div className="img-responsive">
                    <img draggable="false" src="/Assets/images/about-img.png" alt="about-img"
                        className="about-img img-fluid"/>
                </div>
                <div className="rounded-anim">
                    <img draggable="false" src="/Assets/images/round-anim.png" alt="round-animation"/>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex h-100">
                    <div className="my-auto">
                        <h4 className="text-prime fw-bold f-title">About Us</h4>
                        <h1 className="f-title fw-bold lh-base">We have more than about 6+<br />years of experience in
                            IT<br />Solution</h1>
                        <p className=" lh-lg about-p">we are freelancer based in the India and we have been building
                            noteworthy Android , ios and Web for years, which comply with the latest technology and
                            trends. we help convert a vision and an idea into meaningful Mobile Application and
                            Websites. Having a sharp eye for product evolution helps us prioritize tasks, iterate fast
                            and deliver faster.
                        </p>
                        <div>
                            <a href="#contact" className="btn my-btn fw-bold btn-lg bg-prime rounded-pill text-white">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}
export default About