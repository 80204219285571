import React, { useState } from 'react'
import {newsletter} from '../api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const NewsLetters = () => {
  const [email, setEmail] = useState("");
  const data=({
  email: email,
  })
    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
          let res = await newsletter(data)
          console.log("news",data)
          if (res.status === 200) {
            setEmail("");
            toast("Subscribe Successfully!")
          } else {
            console.log("123")
          }
        } catch (err) {
          console.log(err);
        }
      };
  return (
    <>
    <ToastContainer/>
<section className="m-100 newslater">
    <div className="news-grid">
        <img src="/Assets/images/techGrid2.png" alt="" />
    </div>
    <div className="news-grid2">
        <img src="/Assets/images/techGrid.png" alt="" />
    </div>
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div>
                    <img draggable="false" src="/Assets/images/newslatter.png" alt="newslatter"
                        className="img-fluid p-3 newLater-img" />
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex h-100">
                    <form onSubmit={handleSubmit} className="my-auto">
                        <h1 className="fw-bold f-title">Subscribe Our News Letter</h1>
                        <h4>Get news from us in your inbox</h4>
                        <input type="email" className="form-control my-4 py-3 px-4 rounded-pill" name="email"
                           value={email}  onChange={(e) => setEmail(e.target.value)}  placeholder="Enter Your E-mail here" required />
                        <button type="submit" className="btn bg-prime my-btn btn-lg rounded-pill fw-bold px-4 text-white">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}

export default NewsLetters