import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Service = () => {
const state= {
responsive:{
0: {
items: 1,
},
600: {
items: 2,
},
1000: {
items: 3,
},
},
}
return (
<>
    <section className="m-100" id="services">
        <div className="container">
            <h1 className="text-center fw-bolder my-5 f-title">
                <i className="fad fa-horizontal-rule"></i> Services <i className="fad fa-horizontal-rule"></i>
            </h1>
            <div className="row">
                <div className="col-md-3">
                    {/* <OwlCarousel items={4} margin={15} responsive={state.responsive}> */}
                        <div className="item my-3">
                            <div className="d-flex flex-column align-items-center p-4 service-item" style={{padding: "2rem"}}>
                                <div className="service-dot dot-1"></div>
                                <div className="service-dot dot-2"></div>
                                <div className="service-dot dot-3"></div>
                                <div className="animation-service"><i className="fas fa-times h2 d-inline"></i></div>
                                <h2
                                    className="p-xl-4 p-3 i-s btn-blue detail-icon text-center rounded-circle details-icon">
                                    <i className="fad fa-laptop-code"></i>
                                </h2>
                                <h3 className="f-title fw-bold mt-4 text-center">Website Development</h3>
                                <p className="text-center px-4 lh-base my-2">Building bridges in the digital landscape
                                    with precision and creativity.</p>
                                <div>
                                    <a href="#contact"
                                        className="btn btn-lg my-btn service-btn rounded-pill fw-bold px-4 bg-prime mt-3">Go
                                        now</a>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="col-md-3">
                    <div className="item my-3">
                        <div className="d-flex flex-column align-items-center p-4 service-item" style={{padding: "2rem"}}>
                            <div className="service-dot dot-1"></div>
                            <div className="service-dot dot-2"></div>
                            <div className="service-dot dot-3"></div>
                            <div className="animation-service"><i className="fas fa-times h2 d-inline"></i></div>
                            <h2 className="p-xl-4 p-3 i-s btn-blue detail-icon text-center rounded-circle details-icon">
                                <i className="fad fa-laptop-code"></i>
                            </h2>
                            <h3 className="f-title fw-bold mt-4 text-center">Software Development</h3>
                            <p className="text-center px-4 lh-base my-2">Empowering businesses through smart, scalable
                                software</p>
                            <div>
                                <a href="#contact"
                                    className="btn btn-lg my-btn service-btn rounded-pill fw-bold px-4 bg-prime mt-3">Go
                                    now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="item my-3">
                        <div className="d-flex flex-column align-items-center  p-4 service-item" style={{padding: "2rem"}}>
                            <div className="service-dot dot-1"></div>
                            <div className="service-dot dot-2"></div>
                            <div className="service-dot dot-3"></div>
                            <div className="animation-service"><i className="fas fa-times h2 d-inline"></i></div>
                            <h2 className="p-xl-4 p-3 i-s btn-blue detail-icon text-center rounded-circle details-icon">
                                <i className="fad fa-laptop-code"></i>
                            </h2>
                            <h3 className="f-title fw-bold mt-4 text-center">E-Commerce Solutions</h3>
                            <p className="text-center px-4 lh-base my-2">From browsing to buying, we optimize every
                                e-commerce journey.</p>
                            <div>
                                <a href="#contact"
                                    className="btn btn-lg my-btn service-btn rounded-pill fw-bold px-4 bg-prime mt-3">Go
                                    now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="item my-3">
                        <div className="d-flex flex-column align-items-center p-4 service-item" style={{padding: "2rem"}}>
                            <div className="service-dot dot-1"></div>
                            <div className="service-dot dot-2"></div>
                            <div className="service-dot dot-3"></div>
                            <div className="animation-service"><i className="fas fa-times h2 d-inline"></i></div>
                            <h2 className="p-xl-4 p-3 i-s btn-blue detail-icon text-center rounded-circle details-icon">
                                <i className="fad fa-laptop-code"></i>
                            </h2>
                            <h3 className="f-title fw-bold mt-4 text-center">Health Care Solutions</h3>
                            <p className="text-center px-4 lh-base my-2">Empowering health, one click at a time with our
                                web-based solutions.</p>
                            <div>
                                <a href="#contact"
                                    className="btn btn-lg my-btn service-btn rounded-pill fw-bold px-4 bg-prime mt-3">Go
                                    now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="item my-3">
                        <div className="d-flex flex-column align-items-center p-4 service-item" style={{padding: "2rem"}}>
                            <div className="service-dot dot-1"></div>
                            <div className="service-dot dot-2"></div>
                            <div className="service-dot dot-3"></div>
                            <div className="animation-service"><i className="fas fa-times h2 d-inline"></i></div>
                            <h2 className="p-xl-4 p-3 i-s btn-blue detail-icon text-center rounded-circle details-icon">
                                <i className="fad fa-laptop-code"></i>
                            </h2>
                            <h3 className="f-title fw-bold mt-4 text-center">ERP<br />Systems</h3>
                            <p className="text-center px-4 lh-base my-2">Navigate complexity effortlessly with our comprehensive ERP systems.</p>
                            <div>
                                <a href="#contact"
                                    className="btn btn-lg my-btn service-btn rounded-pill fw-bold px-4 bg-prime mt-3">Go
                                    now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="item my-3">
                        <div className="d-flex flex-column align-items-center  p-4 service-item" style={{padding: "2rem"}}>
                            <div className="service-dot dot-1"></div>
                            <div className="service-dot dot-2"></div>
                            <div className="service-dot dot-3"></div>
                            <div className="animation-service"><i className="fas fa-times h2 d-inline"></i></div>
                            <h2 className="p-xl-4 p-3 i-s btn-blue detail-icon text-center rounded-circle details-icon">
                                <i className="fad fa-laptop-code"></i>
                            </h2>
                            <h3 className="f-title fw-bold mt-4 text-center">CRM <br />Systems</h3>
                            <p className="text-center px-4 lh-base my-2">Turning leads into loyalty through seamless CRM
                                integration.</p>
                            <div>
                                <a href="#contact"
                                    className="btn btn-lg my-btn service-btn rounded-pill fw-bold px-4 bg-prime mt-3">Go
                                    now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="item my-3">
                        <div className="d-flex flex-column align-items-center p-4 service-item" style={{padding: "2rem"}}>
                            <div className="service-dot dot-1"></div>
                            <div className="service-dot dot-2"></div>
                            <div className="service-dot dot-3"></div>
                            <div className="animation-service"><i className="fas fa-times h2 d-inline"></i></div>
                            <h2 className="p-xl-4 p-3 i-s btn-blue detail-icon text-center rounded-circle details-icon">
                                <i className="fad fa-laptop-code"></i>
                            </h2>
                            <h3 className="f-title fw-bold mt-4 text-center">Logistics <br />Solutions</h3>
                            <p className="text-center px-4 lh-base my-2">Navigating supply chains with precision through our logistics expertise.</p>
                            <div>
                                <a href="#contact"
                                    className="btn btn-lg my-btn service-btn rounded-pill fw-bold px-4 bg-prime mt-3">Go
                                    now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="item my-3">
                        <div className="d-flex flex-column align-items-center  p-4 service-item" style={{padding: "2rem"}}>
                            <div className="service-dot dot-1"></div>
                            <div className="service-dot dot-2"></div>
                            <div className="service-dot dot-3"></div>
                            <div className="animation-service"><i className="fas fa-times h2 d-inline"></i></div>
                            <h2 className="p-xl-4 p-3 i-s btn-blue detail-icon text-center rounded-circle details-icon">
                                <i className="fad fa-laptop-code"></i>
                            </h2>
                            <h3 className="f-title fw-bold mt-4 text-center">Mobile<br /> Apps</h3>
                            <p className="text-center px-4 lh-base my-2">Transforming ideas into interactive apps for a
                                connected world.</p>
                            <div>
                                <a href="#contact"
                                    className="btn btn-lg my-btn service-btn rounded-pill fw-bold px-4 bg-prime mt-3">Go
                                    now</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </OwlCarousel> */}
            </div>
        </div>
    </section>
</>
)
}

export default Service