import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contactus } from '../api';

const ContactUs = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [yourmessage, setYourmessage] = useState("");
   const data=({
    name: name,
    email: email,
    phone: phone,
    message:yourmessage,
  })
    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
          let res = await contactus(data)
          console.log("contactus",data)
          if (res.status === 200) {
            setName("");
            setEmail("");
            setPhone("");
            setYourmessage("");
            toast("contact details submit successfully!");
          }
          else {
            console.log("123")
          }
        }
        catch (err)
        {
          console.log(err);
        }
      };
  return (
    <>
     <ToastContainer />
    <section className="m-100" id="contact">
    <h1 className="text-center fw-bolder mt-5 mb-3 f-title">
        <i className="fad fa-horizontal-rule"></i> Contact Us <i className="fad fa-horizontal-rule"></i>
    </h1>
    <h4 className="text-center fw-bolder f-title mb-lg-5 mb-md-4 mb-3 ">We appreciate your impact!</h4>
    <div className="container">
        <form onSubmit={handleSubmit} className="row align-items-center">
            <div className="col-lg-6 d-flex flex-column">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control"  value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} name="name" id="floatingInput" required />
                    <label htmlFor="floatingInput">Name</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="email" className="form-control" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} name="email" id="floatingInput1" required />
                    <label htmlFor="floatingInput1">E-mail</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="number"  value={phone} placeholder="Phone" onChange={(e) => setPhone(e.target.value)} className="form-control" name="phone" id="floatingInput2"
                        required />
                    <label htmlFor="floatingInput2">Phone</label>
                </div>
                <div className="form-floating">
                    <textarea className="form-control" value={yourmessage} placeholder="Messages" onChange={(e) => setYourmessage(e.target.value)} name="message"
                        id="floatingTextarea2" style={{height: "100px"}} required></textarea>
                    <label htmlFor="floatingTextarea2">Messages</label>
                </div>
                <button type="submit"
                    className="btn btn-lg my-btn service-btn rounded-pill fw-bold px-4 bg-prime mt-3">Submit</button>
            </div>
            <div className="col-lg-6 p-5">
                <img draggable="false" src="/Assets/images/contactUs.png" alt="" className="img-fluid contact-img"/>
            </div>
        </form>
        <div className="row">
            <div className="col-lg-6">
                <div className="d-flex align-items-center">
                    <div className="contact-icon">
                        <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Call</h5>
                        <Link className="card-text text-dark" to="tel:+912613564802">+91 261-356-4802</Link>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="contact-icon">
                        <i className="fas fa-envelope"></i>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Email</h5>
                        <Link className="card-text text-dark" to="mailto:conatct@doubleplusinfotech.com">
                        contact@doubleplusinfotech.com</Link>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="contact-icon">
                        <i className="fas fa-user-clock"></i>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Time</h5>
                        <p className="card-text ">09:00 to 18:00</p>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="contact-icon">
                        <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Reach Us @</h5>
                        <Link to="https://goo.gl/maps/pRZoMQPn14t9Lj4u6" style={{color:"#253858"}}>
                            <p className="card-text font-size-16">322, Rise On plaza, Above ICICI Bank, Sarthana Jakatnaka, Surat, Gujarat - 395006
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="border-white-10">
                    <div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.36628523671!2d72.86428291527014!3d21.217319085896452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f6b929b82a9%3A0x91390f10f8da0b89!2sDOUBLE%20PLUS%20INFOTECH%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1635760146245!5m2!1sen!2sin"
                            className="map" style={{border:"0"}} allowFullScreen="" loading="lazy">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}

export default ContactUs