import React from 'react'

const Clients = ({data}) => {
  return (
    <>
    <section className="m-100 clints">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="row h-100 justify-content-center progress-main">
                    <div className="col-md-6">
                        <div className="progress-item">
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <h2 className="p-xl-4 p-3 i-s btn-blue progress-icon text-center rounded-circle">
                                    <i className="fas fa-user-tie numbers-icon"></i>
                                </h2>
                                <h2 className=" fw-bold text-center mb-1 mt-2">{data.TotalClients}+</h2>
                                <h3 className="text-center fw-bold">Total Clients</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="progress-item">
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <h2 className="p-xl-4 p-3 i-s btn-blue progress-icon text-center rounded-circle">
                                    <i className="fas fa-check numbers-icon"></i>
                                </h2>
                                <h2 className="fw-bold text-center mb-1 mt-2">{data.TotalProjects}+</h2>
                                <h3 className="text-center fw-bold">Projects Done</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="progress-item">
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <h2 className="p-xl-4 p-3 i-s btn-blue progress-icon text-center rounded-circle">
                                    <i className="far fa-code numbers-icon"></i>
                                </h2>
                                <h2 className=" fw-bold text-center mb-1 mt-2">{data.TotalDevelopers}+</h2>
                                <h3 className="text-center fw-bold"> Developers</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="progress-item">
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <h2 className="p-xl-4 p-3 i-s btn-blue progress-icon text-center rounded-circle">
                                    <i className="fas fa-vote-yea numbers-icon"></i>
                                </h2>
                                <h2 className=" fw-bold text-center mb-1 mt-2">{data.YearExperience}+</h2>
                                <h3 className="text-center fw-bold">Years experiance</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex justify-content-center">
                    <img draggable="false" src="/Assets/images/progress-img.png" alt="progress-img"
                        className="img-fluid p-4 progress-img" />
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}
export default Clients