import React from 'react'
import { Link } from 'react-router-dom'

const Website = () => {
  return (
    <>
    <section className="website m-100">
    <div className="wesite-anim">
        <img draggable="false" src="/Assets/images/website-anim.png" alt="website-anim" className="img-fluid" />
    </div>
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="d-flex justify-content-center img-responsive">
                    <img draggable="false" src="/Assets/images/website.png" alt="website" className="img-fluid website-img" />
                </div>
            </div>
            <div className="col-lg-6">
                <div className="d-flex flex-column h-100">
                    <div className="my-auto">
                        <h1 className="fw-bold f-title mb-lg-4 mb-3">Grow Your Business With Us</h1>
                        <ul>
                            <li className="h4 my-4 website-icon">Perfect And Clean Code</li>
                            <li className="h4 my-4 website-icon">Search Engine Optimization For Show Your Site Top</li>
                            <li className="h4 my-4 website-icon">Create Your App Without Any Single Bug</li>
                            <li className="h4 my-4 website-icon">Create Your Business’s Graphic Work With Us</li>
                        </ul>
                        <Link to="#contact"
                            className="btn btn-lg rounded-pill fw-bold bg-prime text-white px-4 py-2 my-btn">Contact
                            Us</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}

export default Website