import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const MainBanner = () => {
    const [titleName,setTitleName] = useState("Web Development")
    useEffect(() => {
        const values = ['Software Development', 'E-commerce Solutions','Health Care Solutions','ERP System','CRM Systems','Logistics Solutions','Mobile Apps','Website Development'];
        let currentIndex = 0;
        const intervalId = setInterval(() => {
        setTitleName(values[currentIndex]);
          currentIndex = (currentIndex + 1) % values.length;
        }, 3000);
        return () => {
          clearInterval(intervalId);
        };
    }, []);
  return (
    <>
     <div>
        <Link to="#home" className="text-white">
            <h2 className="go-to-top">
                <i className="fas fa-angle-up"></i>
            </h2>
        </Link>
    </div>
    {/* <!-- ==================================================== -->
    <!-- ================= main header and hero section ================= --> */}
    <section className="main-hero" id="home">
        <div className="animation-dots one"></div>
        <div className="animation-dots two"></div>
        <div className="animation-dots three"></div>
        <div className="animation-dots four"></div>
        <div className="animation-dots five"></div>
        <div className="animation-dots six"></div>
        <div className="animation-dots seven"></div>
        <div className="animation-dots eight"></div>
        <div className="animation-dots nine"></div>
        <div className="grid1">
            <img draggable="false" src="/Assets/images/Grid1.png" alt="" className="img-fluid" />
        </div>
        <div className="grid2">
            <img draggable="false" src="/Assets/images/Grid2.png" alt="" className="img-fluid" />
        </div>
        <div className="container ">
            <div className="header">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="nav-logo">
                        <img draggable="false" src="../Assets/images/logo.png" className="img-fluid" alt="logo" />
                    </div>
                    <div className="text-white menu-btn">
                        <i className="fas fa-bars m-0"></i>
                    </div>
                    <ul className="nav-list">
                        <li><a href="#" className="text-white">Home</a></li>
                        <li><a href="#aboutus" className="text-white">About Us</a></li>
                        <li className="dropdown">
                            <a href="#services" className="text-white">Services</a>
                        </li>
                        <li><a href="#blog" className="text-white">Blog</a></li>
                        <li><a href="#hire" className="text-white">Hiring</a></li>
                        <li><a href="#contact" className="text-white">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row banner-text">
                <div className="col-lg-6">
                    <div className="d-flex h-100">
                        <div className="my-auto">
                            <h1 className="fw-bold lh-base mb-2 text-uppercase">Double Plus <br /> Infotech PVT. LTD</h1>
                            <h3 className="fw-bolder">We Provide</h3>
                            <div className="d-inline-block">
                                <h3 className="my-2 fw-bolder services-blink">{titleName}</h3>
                            </div>
                            <div>
                                <Link to="#aboutus"
                                    className="btn my-btn bg-prime text-white fw-bold px-4 btn-lg mt-3 py-2 rounded-pill">Let's
                                    Start</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 main-banner-hover">
                    <div className="img-responsive">
                        <img draggable="false" src="/Assets/images/mainbanner-img.png" alt="main-banner"
                            className="img-fluid main-banner-img " />
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default MainBanner